<section class="resume-section" id="about">
  <div class="resume-section-content">
    <h1 class="mb-0">
      Geovane
      <span class="text-primary">Cavalcante</span>
    </h1>
    <div class="subheading mb-5">
      Imperatriz, Maranhão +55999991065389 ·
      <a href="mailto:name@email.com">developer@geovanecavalcante.com</a>
    </div>
    <p class="lead mb-5" style="text-align: justify;">Sou bacharel em Sistemas de informação na Wyden/Facimp, amante da
      tecnologia, especialmente na área de desenvolvimento de software e entusiasta no movimento de software livre.

      Tenho experiência como desenvolvedor Full-Stack (tecnologias de back-end e front-end).

      Node.js, Angular, TypeScript, JavaScript, Python, PHP, AWS e Unit Tests.
    </p>
    <div class="social-icons">
      <a class="social-icon" target="_blank" href="https://www.linkedin.com/in/geovanecavalcante"><i
          class="fab fa-linkedin-in"></i></a>
      <a class="social-icon" target="_blank" href="https://github.com/GeovaneCavalcante"><i
          class="fab fa-github"></i></a>
      <a class="social-icon" target="_blank" href="https://twitter.com/geovane_dev"><i class="fab fa-twitter"></i></a>
      <a class="social-icon" target="_blank" href="https://www.facebook.com/geovannefeitosacavalcante"><i
          class="fab fa-facebook-f"></i></a>
    </div>
  </div>
</section>
