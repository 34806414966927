<section class="resume-section" id="experience">
  <div class="resume-section-content">
    <h2 class="mb-5">Experiências</h2>
    <div class="d-flex flex-column flex-md-row justify-content-between mb-5">
      <div class="flex-grow-1">
        <h3 class="mb-0">Analista de Programação Sênior</h3>
        <div class="subheading mb-3">Infosistemas</div>
        <p>Ferramentas: Git, Node.js, Angular, TypeScript, SQL Server, Scrum, Testes automatizados (Jest),
          Microserviços, Integrações.
        </p>
        <p>Atuando no desenvolvimento de uma nova plataforma web, destacando-se: criação e orquestração das arquiteturas
          dos projetos, criação componentes para facilitação no desenvolvimento dos projetos.</p>
      </div>
      <div class="flex-shrink-0"><span class="text-primary">Janeiro 2021 - Abril 2021</span></div>
    </div>
    <div class="d-flex flex-column flex-md-row justify-content-between mb-5">
      <div class="flex-grow-1">
        <h3 class="mb-0">Software Developer</h3>
        <div class="subheading mb-3">Seges Tecnologia</div>
        <p>Principais tecnologias usadas: NodeJs, Angular, JavaScript, Typescript, PHP, Python, AWS, SQL e noSQL, Ionic.
        </p>
        <p>Modelando e construindo soluções para vários segmentos, dentre eles, monitoramento, manutenção preventiva e
          medição de produtividade de máquinas pesadas. Implantando uma plataforma com modelo de gestão próprio, com
          foco comercial em empresas do ramo de transporte e minério. Automatizar rotinas através do comportamento dos
          usuários e equipamentos, construção de soluções na área fiscal com NF-e, MDF-e e CT-e; Interfaceamento de
          equipamentos com sistema web.</p>
        <p>O sistema conta com micro-serviços, utilizando a estrutura serverless da amazon, mas também contando com ec2
          para manter o sistema legado.</p>
      </div>
      <div class="flex-shrink-0"><span class="text-primary">Fevereiro 2017 - Dezembro 2020</span></div>
    </div>
    <div class="d-flex flex-column flex-md-row justify-content-between mb-5">
      <div class="flex-grow-1">
        <h3 class="mb-0">Monitor Acadêmico</h3>
        <div class="subheading mb-3">Facimp Wyden</div>
        <p>Com o objetivo de ajudar com algumas disciplinas ofertadas no curso, trabalhei como monitor em disciplinas
          voltadas à programação, durante esse período o aluno interessado fazia o contato com o setor responsável no
          qual o mesmo alocava aulas extras para esses alunos com minha ministração.</p>
      </div>
      <div class="flex-shrink-0"><span class="text-primary">Fevereiro 2016 - Dezembro 2017</span></div>
    </div>
    <div class="d-flex flex-column flex-md-row justify-content-between mb-5">
      <div class="flex-grow-1">
        <h3 class="mb-0">Professor</h3>
        <div class="subheading mb-3">Infraero</div>
        <p>Responsável por duas turmas de informática básica, fiz o processo de inserção de crianças e adolescentes no
          mundo computacional, ensinando e guiando seus primeiros passos em uma jornada de 6 meses de aprendizagem.</p>
      </div>
      <div class="flex-shrink-0"><span class="text-primary">Fevereiro 2017 - Dezembro 2017</span></div>
    </div>
    <div class="d-flex flex-column flex-md-row justify-content-between mb-5">
      <div class="flex-grow-1">
        <h3 class="mb-0">Mentor Acadêmico</h3>
        <div class="subheading mb-3">Facimp Wyden</div>
        <p>Período de mentor dos alunos calouros do curso de sistemas de informação através de um programa ofertado pela
          instituição na qual estou graduando, com enfoque em adaptar e ensinar através das minhas experiências
          acadêmicas os alunos recém chegados no curso, além de fazer o papel de ponte entre o setor responsável e o
          alunos.</p>
      </div>
      <div class="flex-shrink-0"><span class="text-primary">Fevereiro 2016 - Dezembro 2017</span></div>
    </div>
  </div>
</section>
