<!-- Navigation-->
<nav class="navbar navbar-expand-lg navbar-dark bg-primary fixed-top" id="sideNav"
  style="background-repeat: no-repeat; background-size:1510px 1010px; background-image: url(/assets/img/capa.jpg);">
  <a class="navbar-brand js-scroll-trigger" href="#page-top">
    <span class="d-block d-lg-none">Geovane Cavalcante</span>
    <span class="d-none d-lg-block"><img class="img-fluid img-profile rounded-circle mx-auto mb-2"
        src="/assets/img/profile.jpg" alt="" /></span>
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span
      class="navbar-toggler-icon"></span></button>
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav">
      <li class="nav-item"><a class="nav-link js-scroll-trigger" href="#about">Sobre</a></li>
      <li class="nav-item"><a class="nav-link js-scroll-trigger" href="#experience">Experiência</a></li>
      <li class="nav-item"><a class="nav-link js-scroll-trigger" href="#education">Formação</a></li>
      <li class="nav-item"><a class="nav-link js-scroll-trigger" href="#skills">Habilidades</a></li>
      <li class="nav-item"><a class="nav-link js-scroll-trigger" href="#interests">Interesses</a></li>
      <li class="nav-item"><a class="nav-link js-scroll-trigger" href="#awards">Prêmios</a></li>
    </ul>
  </div>
</nav>
<!-- Page Content-->
<div class="container-fluid p-0">
  <!-- About-->
  <app-home></app-home>
  <hr class="m-0" />
  <!-- Experience-->
  <app-experience></app-experience>
  <hr class="m-0" />
  <!-- Education-->
  <app-education></app-education>
  <hr class="m-0" />
  <!-- Skills-->
  <app-skills></app-skills>
  <hr class="m-0" />
  <!-- Interests-->
  <app-interests></app-interests>
  <!-- Awards-->
  <app-awards></app-awards>
</div>
