<section class="resume-section" id="skills">
  <div class="resume-section-content">
    <h2 class="mb-5">Habilidades</h2>
    <div class="subheading mb-3">LINGUAGENS E FERRAMENTAS DE PROGRAMAÇÃO</div>
    <ul class="list-inline dev-icons">
      <li class="list-inline-item"><i class="fab fa-html5"></i></li>
      <li class="list-inline-item"><i class="fab fa-css3-alt"></i></li>
      <li class="list-inline-item"><i class="fab fa-js-square"></i></li>
      <li class="list-inline-item"><i class="fab fa-angular"></i></li>
      <li class="list-inline-item"><i class="fab fa-git-alt"></i></li>
      <li class="list-inline-item"><i class="fab fa-node-js"></i></li>
      <li class="list-inline-item"><i class="fab fa-python"></i></li>
      <li class="list-inline-item"><i class="fab fa-php"></i></li>
      <li class="list-inline-item"><i class="fas fa-database"></i></li>
      <li class="list-inline-item"><i class="fas fa-server"></i></li>
      <li class="list-inline-item"><i class="fab fa-docker"></i></li>
      <li class="list-inline-item"><i class="fab fa-yarn"></i></li>
      <li class="list-inline-item"><i class="fab fa-npm"></i></li>
      <li class="list-inline-item"><i class="fab fa-aws"></i></li>
      <li class="list-inline-item"> <i class="fab fa-laravel"></i></li>


    </ul>
    <div class="subheading mb-3">FLUXO DE TRABALHO</div>
    <ul class="fa-ul mb-0">
      <li>
        <span class="fa-li"><i class="fas fa-check"></i></span>
        Modelagem de aplicações
      </li>
      <li>
        <span class="fa-li"><i class="fas fa-check"></i></span>
        Backend - Python Flask, Django
      </li>
      <li>
        <span class="fa-li"><i class="fas fa-check"></i></span>
        Backend - PHP Laravel, Codeigniter
      </li>
      <li>
        <span class="fa-li"><i class="fas fa-check"></i></span>
        Backend - NodeJs, Express, NestJs
      </li>
      <li>
        <span class="fa-li"><i class="fas fa-check"></i></span>
        Frontend - HTML, CSS, JS, Angular
      </li>
      <li>
        <span class="fa-li"><i class="fas fa-check"></i></span>
        Mobile - Ionic 2+
      </li>
      <li>
        <span class="fa-li"><i class="fas fa-check"></i></span>
        Raspagem de dados - Python, Selenium, Scrapy, Requests2
      </li>
      <li>
        <span class="fa-li"><i class="fas fa-check"></i></span>
        Automatização de Tarefas - Python
      </li>
      <li>
        <span class="fa-li"><i class="fas fa-check"></i></span>
        Integração de Plataformas - API REST, GraphQL
      </li>
      <li>
        <span class="fa-li"><i class="fas fa-check"></i></span>
        Redes Definidas por Software - Contralador Pox, OpenFlow
      </li>
      <li>
        <span class="fa-li"><i class="fas fa-check"></i></span>
        Banco de dados - Firebird, MySQL, Postgres
      </li>
      <li>
        <span class="fa-li"><i class="fas fa-check"></i></span>
        Banco de dados NoSQL - Mongo, Redis
      </li>
      <li>
        <span class="fa-li"><i class="fas fa-check"></i></span>
        Conteineres - Docker
      </li>
      <li>
        <span class="fa-li"><i class="fas fa-check"></i></span>
        Controle de versão - Git
      </li>
      <li>
        <span class="fa-li"><i class="fas fa-check"></i></span>
        Servidores Linux
      </li>
      <li>
        <span class="fa-li"><i class="fas fa-check"></i></span>
        AWS(Amazon Web Services)
      </li>
    </ul>
  </div>
</section>
