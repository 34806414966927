<section class="resume-section" id="interests">
  <div class="resume-section-content">
    <h2 class="mb-5">Interesses</h2>
    <div class="subheading mb-3">Estudos e Trabalhos</div>
    <ul class="fa-ul mb-0">
      <li>
        <span class="fa-li"><i class="fas fa-book-open"></i></span>
        Desenvolvimento Web Frontend
      </li>
      <li>
        <span class="fa-li"><i class="fas fa-book-open"></i></span>
        Desenvolvimento Web Backend
      </li>
      <li>
        <span class="fa-li"><i class="fas fa-book-open"></i></span>
        Automatização de tarefas
      </li>
      <li>
        <span class="fa-li"><i class="fas fa-book-open"></i></span>
        Modelagem de aplicações
      </li>
      <li>
        <span class="fa-li"><i class="fas fa-book-open"></i></span>
        Arquitetura de Software
      </li>
      <li>
        <span class="fa-li"><i class="fas fa-book-open"></i></span>
        Paradigmas de Software
      </li>
      <li>
        <span class="fa-li"><i class="fas fa-book-open"></i></span>
        Raspagem de dados
      </li>
      <li>
        <span class="fa-li"><i class="fas fa-book-open"></i></span>
        Microsserviços
      </li>
      <li>
        <span class="fa-li"><i class="fas fa-book-open"></i></span>
        Redes Definidas por Software
      </li>
      <li>
        <span class="fa-li"><i class="fas fa-book-open"></i></span>
        Sistema operacional Linux
      </li>
      <li>
        <span class="fa-li"><i class="fas fa-book-open"></i></span>
        AWS(Amazon Web Services)
      </li>
    </ul>
  </div>
</section>
<hr class="m-0" />
