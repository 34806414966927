<section class="resume-section" id="awards">
  <div class="resume-section-content">
    <h2 class="mb-5">Prêmios</h2>
    <ul class="fa-ul mb-0">
      <li>
        <span class="fa-li"><i class="fas fa-trophy text-warning"></i></span>
        2º Capture the flag
        - Faculdade de Imperatriz - 2019
      </li>
      <li>
        <span class="fa-li"><i class="fas fa-trophy text-warning"></i></span>
        3º Capture the flag
        - Faculdade de Imperatriz - 2018
      </li>
      <li>
        <span class="fa-li"><i class="fas fa-trophy text-warning"></i></span>
        1º Capture the flag
        - Faculdade de Imperatriz - 2017
      </li>
      <li>
        <span class="fa-li"><i class="fas fa-trophy text-warning"></i></span>
        3º Capture the flag
        - Faculdade de Imperatriz - 2016
      </li>
      <li>
        <span class="fa-li"><i class="fas fa-trophy text-warning"></i></span>
        Top 10
        Corrida de Startups - Faculdade de Imperatriz - 2016
      </li>
    </ul>
  </div>
</section>
