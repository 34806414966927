<section class="resume-section" id="education">
  <div class="resume-section-content">
    <h2 class="mb-5">Formação</h2>
    <div class="d-flex flex-column flex-md-row justify-content-between mb-5">
      <div class="flex-grow-1">
        <h3 class="mb-0">Facimp Wyden</h3>
        <div class="subheading mb-3">Bacharel em Sistemas de Informação</div>
        <div>Sistemas de Informação - Desenvolvedor de Software</div>
      </div>
      <div class="flex-shrink-0"><span class="text-primary">Fevereiro 2016 - Janeiro 2020</span></div>
    </div>
  </div>
</section>
